import { useFlags } from 'flagsmith/react';
import { FeatureFlags } from '@lawnstarter/customer-modules/enums';

import { AuthorizedServicesTemplate } from './AuthorizedServicesTemplate';
import { AuthorizedServicesTemplateV2 } from './AuthorizedServicesTemplateV2';

import type { User } from '@lawnstarter/ls-react-common/types';

export function AuthorizedServicesTemplateWrapper({ user }: { user?: User }) {
  const { enabled: isNewServicesLayout } = useFlags([FeatureFlags.SERVICES_TAB_IMPROVEMENTS_WEB])[
    FeatureFlags.SERVICES_TAB_IMPROVEMENTS_WEB
  ];

  return isNewServicesLayout ? (
    <AuthorizedServicesTemplateV2 user={user} />
  ) : (
    <AuthorizedServicesTemplate user={user} />
  );
}
