import { View } from 'react-native';
import { t } from '@lawnstarter/customer-modules/services';
import { Text } from '@lawnstarter/ls-react-common/atoms';
import { currency } from '@lawnstarter/ls-react-common/filters';

import { useStyles } from './styles';

import type { OptionLabelProps } from './types';

export function OptionLabel({
  disabled = false,
  option,
  selected,
  servicePrice = 0,
  visible = true,
}: OptionLabelProps) {
  const styles = useStyles(disabled, visible);

  const isAmountDefined = option.amount !== undefined && selected?.amount;

  const isPercentageDefined = servicePrice && option.percentage;

  return (
    <View style={styles.container}>
      <Text
        variant={option.key === 'none' ? 'bodyMedium' : 'titleSmall'}
        style={option.key === 'none' ? styles.labelRegular : styles.labelBold}
      >
        {t(`review.tipOptions.labels.${option.key}`)}
      </Text>

      {isAmountDefined || isPercentageDefined ? (
        <>
          <Text style={styles.labelRegular}> - </Text>
          <Text style={styles.labelRegular}>
            {isAmountDefined
              ? currency({ amount: selected.amount })
              : currency({ amount: (servicePrice * option.percentage!) / 100 })}
          </Text>
        </>
      ) : null}
    </View>
  );
}
