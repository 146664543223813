import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';
import { setFontWeight } from '@lawnstarter/ls-react-common';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const style = (theme: AppTheme) =>
  StyleSheet.create({
    value: {
      width: '55px',
      textAlign: 'right',
      ...setFontWeight('700'),
    },
    fullValue: {
      width: '55px',
      textAlign: 'right',
      textDecorationLine: 'line-through',
      marginRight: theme.spacing.s1,
      ...setFontWeight('700'),
    },
    label: {
      ...setFontWeight('600'),
    },
    inputLabel: { marginBottom: theme.spacing.s1, ...setFontWeight('600') },
  });

export const StyledSummary = tw.div`
  border-[1px] border-graySecondary p-4 rounded flex-1
`;
