import { useCallback } from 'react';
import { POOL_SYSTEMS } from '@lawnstarter/customer-modules/constants';
import { usePropertyDetailsQuestionContext } from '@lawnstarter/customer-modules/contexts';
import { t } from '@lawnstarter/customer-modules/services';
import { setFontWeight } from '@lawnstarter/ls-react-common';
import { Text } from '@lawnstarter/ls-react-common/atoms';
import { SelectOption } from '@lawnstarter/ls-react-common/molecules';

import type { SelectOptionItemKey } from '@lawnstarter/ls-react-common/molecules';
import type { DetailsForPoolCleaning } from '@src/components/organisms/PropertyDetailsQuestionManager/types';

export function PoolSystem() {
  const { update } = usePropertyDetailsQuestionContext<DetailsForPoolCleaning>();

  const updateSystem = useCallback(
    (value: SelectOptionItemKey) => {
      // TODO: Call mutation to update pool system before updating context
      update({ step: 'poolSystem', value });
    },
    [update],
  );

  return (
    <>
      <div className="flex flex-col gap-2 flex-1">
        <Text
          aria-level={2}
          variant="headlineSmall"
          accessibilityRole={'header'}
          style={{ ...setFontWeight('600') }}
        >
          {t('forms.poolQuestions.poolType')}
        </Text>
        <Text>{t('forms.poolQuestions.poolTypeDescription')}</Text>
      </div>

      <div className="flex-1">
        <SelectOption options={POOL_SYSTEMS} onSelect={updateSystem} />
      </div>
    </>
  );
}
