import { useCallback, useMemo } from 'react';
import { Image } from 'react-native';
import PoolWashingImg from '@lawnstarter/customer-modules/assets/modal-images/pool-washing.png';
import { useOrderContext } from '@lawnstarter/customer-modules/contexts';
import { OrderPoolFlowKeys, WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { SelectOption } from '@lawnstarter/ls-react-common/molecules';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import { OrderPoolCleaningLayout } from '@src/components';
import { useRouteNavigation } from '@src/hooks';

import { styles } from './styles';

import type { SelectOptionItemKey } from '@lawnstarter/ls-react-common/molecules';
import type { ImageSourcePropType } from 'react-native';

export function PoolAboveGroundScreen() {
  const theme = useAppTheme();
  const { navigate } = useRouteNavigation();
  const style = useMemo(() => styles(theme), [theme]);
  const modal = useModal(ModalTemplate.ACTION_CONFIRMATION);
  const { updateOrder, goToNextStep, currentState } = useOrderContext();

  const isPoolAboveGround = currentState?.[OrderPoolFlowKeys.IsPoolAboveGround];
  const selectedValue =
    isPoolAboveGround === undefined ? undefined : isPoolAboveGround ? 'yes' : 'no';

  const onSelect = useCallback(
    (key: SelectOptionItemKey) => {
      if (key === 'yes') {
        return modal.show({
          buttonText: t('goBackToBrowse'),
          titleStyle: style.modalTitle,
          title: t('services.poolCleaning.orderService.poolAboveGround.unsupported'),
          icon: <Image source={PoolWashingImg as ImageSourcePropType} style={style.modalImage} />,
          onDismiss: () => navigate(WebRoutes.browseRedirect),
        });
      }

      updateOrder({ [OrderPoolFlowKeys.IsPoolAboveGround]: key === 'yes' });
      goToNextStep();
    },
    [modal, style, navigate, goToNextStep, updateOrder],
  );

  return (
    <OrderPoolCleaningLayout
      title={t('services.poolCleaning.orderService.poolAboveGround.title')}
      description={t('services.poolCleaning.orderService.poolAboveGround.description')}
      progress={1 / 4}
    >
      <SelectOption
        selected={selectedValue}
        onSelect={onSelect}
        allowReselection
        options={[
          { key: 'yes', label: t('yes') },
          { key: 'no', label: t('no') },
        ]}
      />
    </OrderPoolCleaningLayout>
  );
}
