import { StyleSheet } from 'react-native';
import { setFontWeight } from '@lawnstarter/ls-react-common';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const styles = (theme: AppTheme) =>
  StyleSheet.create({
    initialsContainer: {
      borderRadius: 100,
      height: theme.sizing.s12,
      width: theme.sizing.s12,
      backgroundColor: theme.colors.primary,
    },
    initials: {
      ...setFontWeight('700'),
      textAlign: 'center',
      paddingVertical: theme.spacing.s8,
      paddingHorizontal: theme.spacing.s4,
      lineHeight: theme.sizing.s4,
      padding: 0,
      color: theme.colors.white.primary,
    },
    listBox: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing.s1,
      borderRadius: theme.radius.s2,
      overflow: 'hidden',
      backgroundColor: theme.colors.white.primary,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.colors.gray.secondary,
      outlineStyle: 'none',
    },
    dropdownItem: {
      padding: '0.75rem',
      fontSize: 'inherit',
      border: 'none',
      textAlign: 'left',
      outlineStyle: 'hidden',
      overflow: 'hidden',
      paddingInline: theme.spacing.s5,
    },
    dropdownProfileName: {
      fontSize: theme.fonts.bodyLarge.fontSize,
      cursor: 'default',
    },
    dropdownProfileEmail: {
      color: theme.colors.gray.primary,
      cursor: 'default',
    },
    dropdownSeparator: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.colors.gray.secondary,
    },
  });
