import { t } from '@lawnstarter/customer-modules/services';
import { longGrassLengthCutoffInInches } from '@lawnstarter/ls-constants';

import type { PropertyDetailsFormFieldTexts } from '@lawnstarter/ls-react-common/organisms';

const { fee: min, quote: max } = longGrassLengthCutoffInInches;

export const formLabels: PropertyDetailsFormFieldTexts = {
  controls: {
    yes: t('yes'),
    no: t('no'),
  },
  property: {
    additionalInstructions: {
      label: t('forms.propertyDetails.additionalInstructions'),
    },
    gate: {
      exists: {
        label: t('forms.propertyDetails.gate.exists'),
      },
      width: {
        label: t('forms.propertyDetails.gateWidth'),
        options: {
          under24: {
            label: t('forms.propertyDetails.gate.width.under', { value: 24 }),
          },
          under48: {
            label: t('forms.propertyDetails.gate.width.under', { value: 48 }),
          },
          over48: {
            label: t('forms.propertyDetails.gate.width.over', { value: 48 }),
          },
        },
      },
      lock: {
        label: t('forms.propertyDetails.gateLocked'),
        options: {
          no: {
            label: t('no'),
          },
          key: {
            label: t('forms.propertyDetails.lockedWithKey'),
          },
          code: {
            label: t('forms.propertyDetails.lockedWithCode'),
          },
        },
        code: {
          label: t('forms.propertyDetails.accessCode'),
        },
      },
    },
    hasPets: {
      label: t('forms.propertyDetails.petsOutside'),
    },
    isCornerlot: {
      label: t('forms.propertyDetails.cornerlot'),
    },
    multitenant: {
      isMultitenant: {
        label: t('forms.propertyDetails.isMultiFamily'),
      },
      unit: {
        label: t('forms.propertyDetails.multiFamilyUnit'),
      },
    },
    title: t('propertyDetails'),
  },
  mowing: {
    grassHeight: {
      label: t('forms.propertyDetails.whatIsGrassHeight'),
      options: {
        under6: {
          label: t('forms.propertyDetails.grassLength.under', { min }),
        },
        between6And12: {
          label: t('forms.propertyDetails.grassLength.between', { min, max }),
        },
        over12: {
          label: t('forms.propertyDetails.grassLength.over', { max }),
        },
      },
    },
    additionalInstructions: {
      label: t('forms.propertyDetails.additionalInstructions'),
    },
    scope: {
      label: t('forms.propertyDetails.maintenance.where'),
      options: {
        all: {
          label: t('forms.propertyDetails.maintenance.location.all'),
        },
        backOnly: {
          label: t('forms.propertyDetails.maintenance.location.back'),
        },
        frontOnly: {
          label: t('forms.propertyDetails.maintenance.location.front'),
        },
      },
    },
    title: t('forms.propertyDetails.mowingDetails'),
  },
};
