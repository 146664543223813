import { View } from 'react-native';
import { t } from '@lawnstarter/customer-modules/services';
import { ImageUploadInput, Switch, TextArea, TextInput } from '@lawnstarter/ls-react-common/atoms';

import { DatePickerForm } from '@src/components/atoms';

import { useStyles } from './styles';

import type { ReportDamageFormProps } from './types';

export function ReportDamageForm({ control, isUpdating, watch, setValue }: ReportDamageFormProps) {
  const { styles } = useStyles();

  const hadRecentVisitValue = watch('third_parties', false);

  const minDate = new Date(new Date().setMonth(new Date().getMonth() - 3));
  const maxDate = new Date(new Date().setDate(new Date().getDate() + 1));

  return (
    <View style={styles.container}>
      <DatePickerForm
        control={control}
        name="damage_occurred_at"
        label={t('forms.reportDamage.whenYoufirstNotice')}
        maxDate={maxDate}
        minDate={minDate}
        disabled={isUpdating}
        rules={{ required: true }}
      />

      <TextArea
        control={control}
        name="damage_details"
        rules={{ required: true }}
        label={t('forms.reportDamage.describeTheDamage')}
        disabled={isUpdating}
      />

      <ImageUploadInput
        control={control}
        name="damage_images"
        label={t('forms.uploadPhotos')}
        uploadText={t('forms.upload.uploading')}
        addButton={{ text: t('forms.uploadPhotos') }}
        tooLargeFilesText={t('forms.upload.fileTooLarge')}
        isUploading={isUpdating}
        singleUpload={false}
        rules={{ required: true }}
      />

      <TextInput.Currency
        control={control}
        name="damage_cost"
        rules={{ required: true }}
        label={t('forms.reportDamage.whatIsTheValue')}
        disabled={isUpdating}
      />

      <Switch.Text
        label={t('forms.reportDamage.hadVisitRecently')}
        labelOff={t('no')}
        labelOn={t('yes')}
        value={hadRecentVisitValue}
        onValueChange={() => {
          setValue('third_parties', !hadRecentVisitValue);
        }}
        disabled={isUpdating}
      />
    </View>
  );
}
