import BottomSheet from '@devvie/bottom-sheet';
import { clamp } from 'lodash';
import { useMemo } from 'react';
import { View } from 'react-native';
import tw from 'tailwind-styled-components';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Icon, ScrollView, Text } from '@lawnstarter/ls-react-common/atoms';

import { useBottomSheetController } from '@src/controllers';

import { styles } from './styles';

import type { MobileHeaderEvents } from '@src/enums';
import type { ReactNode } from 'react';

export interface BaseBottomSheetProps {
  openEvent: MobileHeaderEvents;
  children: ReactNode;
  title?: string | ReactNode;
}
export const BaseBottomSheet = ({ openEvent, title, children }: BaseBottomSheetProps) => {
  const theme = useAppTheme();
  const style = useMemo(() => styles(theme), [theme]);

  const {
    height,
    sheetRef,
    allowScroll,
    sheetVisibility,
    closeBottomSheet,
    bottomSheetHeight,
    setBottomSheetHeight,
    updateSheetVisibility,
  } = useBottomSheetController({ pressEvent: openEvent });

  return (
    <StyledHelpDeskBottomSheetWrapper $sheetVisibility={sheetVisibility}>
      <BottomSheet
        height={bottomSheetHeight}
        ref={sheetRef}
        disableBodyPanning
        style={style.sheetContainer}
        onAnimate={(animated: number) => {
          // Avoid weird visual behaviors
          if (animated === 0 && sheetVisibility) {
            updateSheetVisibility(false);
          } else if (animated > 0 && !sheetVisibility) {
            updateSheetVisibility(true);
          }
        }}
        onClose={() => allowScroll()}
        android_closeOnBackPress={false}
      >
        <ScrollView
          contentContainerStyleOverride={{
            overflowY: 'scroll',
            minHeight: 200,
            overflowX: 'hidden',
            padding: 0,
          }}
        >
          {sheetVisibility && (
            <View
              onLayout={({ nativeEvent }) => {
                const bottomSheetHandleHeight = 100;
                const percentage = (nativeEvent.layout.height + bottomSheetHandleHeight) / height;
                setBottomSheetHeight(`${Math.round(clamp(percentage, 0, 0.7) * 100)}%`);
              }}
            >
              <View style={style.header}>
                {title && (
                  <Text variant="headlineSmall" style={style.headerTitle}>
                    {title}
                  </Text>
                )}
                <Button
                  trackID="close-help-desk"
                  testID="close-help-desk"
                  onPress={closeBottomSheet}
                  style={style.closeButtonLabel}
                >
                  <Icon name="close" size={26} />
                </Button>
              </View>
              {children}
            </View>
          )}
        </ScrollView>
      </BottomSheet>
    </StyledHelpDeskBottomSheetWrapper>
  );
};

const StyledHelpDeskBottomSheetWrapper = tw.div<{ $sheetVisibility: boolean }>`
  ${(params) => (params.$sheetVisibility ? 'z-10' : '-z-10')}
  fixed top-0 right-0 left-0 h-full
`;
