import { StyleSheet } from 'react-native';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const style = (theme: AppTheme) =>
  StyleSheet.create({
    description: {
      textAlign: 'center',
      marginBottom: theme.spacing.s4,
    },
    link: { textAlign: 'center' },
    linkContainer: { justifyContent: 'center' },
  });
