import { useParams } from 'react-router-dom';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { properties_currentPropertySelector } from '@lawnstarter/customer-modules/stores/modules';

import { Loader, PropertyDetailsQuestionManager } from '@src/components';
import { useRouteNavigation, useSelector } from '@src/hooks';

import type { PropertyDetailsActiveServices } from '@lawnstarter/customer-modules/enums';

export function PropertyDetailsQuestionsScreen() {
  const { navigate } = useRouteNavigation();
  const { serviceStrId } = useParams<{ serviceStrId: string }>();
  const property = useSelector(properties_currentPropertySelector);

  if (!property) {
    return <Loader />;
  }

  return (
    <div className="p-2">
      <PropertyDetailsQuestionManager
        type={serviceStrId as PropertyDetailsActiveServices}
        onCompleted={() => navigate(WebRoutes.services, { options: { replace: true } })}
      />
    </div>
  );
}
