import QRCode from 'qrcode';
import { useEffect, useState } from 'react';
import { getBrand } from '@lawnstarter/customer-modules/helpers';
import { t } from '@lawnstarter/customer-modules/services';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import { isMobileDevice } from '@src/helpers/mobileWeb';
import { errorService } from '@src/services';

import type { Platform } from 'react-native';

export function useAppDownloadModal() {
  const brand = getBrand();
  const modal = useModal(ModalTemplate.APP_DOWNLOAD);

  const [qrCodeURI, setQrCodeURI] = useState('');

  useEffect(() => {
    const generateQR = async (url: string) => {
      try {
        setQrCodeURI(await QRCode.toDataURL(url));
      } catch (error) {
        errorService.log({ error });
      }
    };

    generateQR(brand.stores.automatic);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDownloadRequest = (platform?: typeof Platform.OS) => {
    switch (platform) {
      case 'android':
        window.open(brand.stores.google, '_blank');
        break;
      case 'ios':
        window.open(brand.stores.apple, '_blank');
        break;
      default:
        window.open(isMobileDevice() ? brand.stores.automatic : brand.stores.apple, '_blank');
        break;
    }
  };

  const showAppDownloadModal = () => {
    return modal.show({
      onDownloadRequest,
      cta: t('appDownload.downloadToContinue'),
      title: t('appDownload.featureUnderConstruction'),
      buttons: {
        dismiss: { text: t('dismiss') },
        download: { text: t('download') },
      },
      qrCode: {
        uri: qrCodeURI,
        title: t('appDownload.scanToDownload'),
      },
    });
  };

  return { showAppDownloadModal, hideAppDownloadModal: modal.hide };
}
