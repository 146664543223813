import { useCallback, useMemo } from 'react';
import { POOL_SIZES } from '@lawnstarter/customer-modules/constants';
import { usePropertyDetailsQuestionContext } from '@lawnstarter/customer-modules/contexts';
import { t } from '@lawnstarter/customer-modules/services';
import { setFontWeight } from '@lawnstarter/ls-react-common';
import { Text } from '@lawnstarter/ls-react-common/atoms';
import { SelectOption } from '@lawnstarter/ls-react-common/molecules';

import type { SelectOptionItemKey } from '@lawnstarter/ls-react-common/molecules';
import type { DetailsForPoolCleaning } from '@src/components/organisms/PropertyDetailsQuestionManager/types';

export function PoolSize() {
  const { update } = usePropertyDetailsQuestionContext<DetailsForPoolCleaning>();

  const updateSize = useCallback(
    (value: SelectOptionItemKey) => {
      // TODO: Call mutation to update pool size before updating context
      update({ step: 'poolSize', value });
    },
    [update],
  );

  const options = useMemo(
    () =>
      POOL_SIZES.map((size) => ({
        ...size,
        label: (
          <div className="flex flex-col flex-1 justify-center items-center">
            <Text variant="titleMedium" style={{ ...setFontWeight('500') }}>
              {size.label}
            </Text>
            <Text variant="titleMedium" style={{ fontStyle: 'italic' }}>
              {size.description}
            </Text>
          </div>
        ),
      })),
    [],
  );

  return (
    <>
      <div className="flex flex-col gap-2 flex-1">
        <Text
          aria-level={2}
          variant="headlineSmall"
          accessibilityRole={'header'}
          style={{ ...setFontWeight('600') }}
        >
          {t('forms.poolQuestions.poolSize')}
        </Text>
        <Text>{t('forms.poolQuestions.poolSizeDescription')}</Text>
      </div>

      <div className="flex-1">
        <SelectOption onSelect={updateSize} options={options} />
      </div>
    </>
  );
}
