import { useCallback } from 'react';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { properties_currentPropertySelector } from '@lawnstarter/customer-modules/stores/modules';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Text } from '@lawnstarter/ls-react-common/atoms';
import { Notice as NoticeType } from '@lawnstarter/ls-react-common/enums';
import { Notice } from '@lawnstarter/ls-react-common/organisms';

import { StyledResponsiveNotice } from '@src/components/atoms';
import { useRouteNavigation, useSelector } from '@src/hooks';

import type { Property } from '@lawnstarter/customer-modules/types';
import type { ServiceType } from '@lawnstarter/ls-react-common/enums';

export function MissingServiceDetailsNotice({ service }: { service: ServiceType }) {
  const theme = useAppTheme();
  const property: Property = useSelector(properties_currentPropertySelector);

  const { navigate } = useRouteNavigation();

  const onPress = useCallback(() => {
    navigate(WebRoutes.propertyDetailsQuestions, {
      params: { serviceStrId: service, propertyId: property?.id },
    });
  }, [navigate, property?.id, service]);

  return (
    <Notice type={NoticeType.Info}>
      <StyledResponsiveNotice>
        <Text>{t(`notices.missingServiceDetails.${service}`) ?? ''}</Text>

        <Button
          trackID="missing-property-service-details-notice_update-now"
          textColor={theme.colors.blue.primary}
          style={{ alignSelf: 'flex-end' }}
          onPress={onPress}
        >
          {t('updateNow')}
        </Button>
      </StyledResponsiveNotice>
    </Notice>
  );
}
