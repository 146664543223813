import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';
import { setFontWeight } from '@lawnstarter/ls-react-common';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const style = (theme: AppTheme, disabled: boolean) => {
  return {
    styles: StyleSheet.create({
      inputLabel: { marginBottom: theme.spacing.s1, ...setFontWeight('600') },

      value: {
        width: '55px',
        textAlign: 'right',
        ...setFontWeight('700'),
      },
      label: {
        ...setFontWeight('600'),
      },
    }),
    stripeStyle: {
      disabled,
      style: {
        base: {
          fontSize: `${theme.fonts.titleMedium.fontSize}px`,
          '::placeholder': {
            color: theme.colors.gray.primary,
          },
        },
        invalid: {
          color: '#fa755a',
        },
      },
    },
  };
};

export const StyledStripeContainer = tw.div`
  px-3 py-4 border-graySecondary border-[1px] rounded font-normal
`;

export const StyledStripeWrapper = tw.div`
  flex flex-row gap-4
`;

export const StyledFormRow = tw.div`
  mb-2
  md:mb-4
`;
