import { useForm } from 'react-hook-form';
import { View } from 'react-native';
import { useParams } from 'react-router-dom';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { support_reportDamage } from '@lawnstarter/customer-modules/stores/modules';
import { Button, ScrollView } from '@lawnstarter/ls-react-common/atoms';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import { DetailsHeader, ReportDamageForm } from '@src/components';
import { useDispatch, useRouteNavigation, useSelector } from '@src/hooks';

import type { ReportDamageFormData } from '@src/components/organisms/forms/ReportDamageForm/types';

export function ReportDamageScreen() {
  const { navigate } = useRouteNavigation();
  const dispatch = useDispatch();
  const messageModal = useModal(ModalTemplate.MESSAGE);

  const { propertyId } = useParams();

  const isUpdating = useSelector((state) => state.support.loadingStatus.isUpdating);

  const { control, handleSubmit, watch, setValue } = useForm<ReportDamageFormData>({
    defaultValues: {
      damage_cost: '',
      damage_details: '',
      damage_images: [],
      damage_occurred_at: new Date().toISOString().split('T')[0],
      third_parties: false,
    },
  });

  async function submit(data: ReportDamageFormData) {
    const response = await dispatch(
      support_reportDamage({
        property_id: propertyId,
        pendingImages: data.damage_images,
        ...data,
      }),
    );

    if (response?.success) {
      messageModal.show({
        message: t('forms.reportDamage.workingOnResolution'),
        onDismiss: () => navigate(WebRoutes.services, { options: { replace: true } }),
      });
    } else {
      messageModal.show({
        message:
          typeof response?.error?.error?.message === 'string'
            ? response.error.error.message
            : t('apiGenericError'),
      });
    }
  }

  return (
    <>
      <DetailsHeader showBackButton />
      <ScrollView>
        <View>
          <ReportDamageForm
            control={control}
            isUpdating={isUpdating}
            watch={watch}
            setValue={setValue}
          />
          <Button
            onPress={handleSubmit(submit)}
            disabled={isUpdating}
            loading={isUpdating}
            trackID="report_damage_screen-submit_report"
            mode="contained"
          >
            {t('continue')}
          </Button>
        </View>
      </ScrollView>
    </>
  );
}
