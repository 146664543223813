import { useFlags } from 'flagsmith/react';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { FeatureFlags, WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import {
  auth_signedCustomerSelector,
  properties_currentPropertySelector,
} from '@lawnstarter/customer-modules/stores/modules';
import { Toast } from '@lawnstarter/ls-react-common/atoms';

import { useRouteNavigation, useSelector } from '@src/hooks';

import { StyledPaymentNotice } from './style';

export function PaymentNotice() {
  const { navigate } = useRouteNavigation();
  const { pathname } = useLocation();
  const property = useSelector(properties_currentPropertySelector);
  const customer = useSelector(auth_signedCustomerSelector);
  const hasPaymentFailure = useMemo(() => customer?.has_payment_failure ?? false, [customer]);

  const { enabled: isNewServicesLayout } = useFlags([FeatureFlags.SERVICES_TAB_IMPROVEMENTS_WEB])[
    FeatureFlags.SERVICES_TAB_IMPROVEMENTS_WEB
  ];

  const backgroundColor = useMemo(
    () => (isNewServicesLayout && pathname === WebRoutes.services ? 'bg-whiteSecondary' : ''),
    [pathname, isNewServicesLayout],
  );

  const onPress = () => navigate(WebRoutes.updatePayment);

  return (
    hasPaymentFailure && (
      <StyledPaymentNotice className={backgroundColor} data-testid="payment-notice">
        <Toast
          description={
            property?.is_active ? t('notices.paymentWillCancel') : t('notices.paymentCanceled')
          }
          variant="alert"
          action={{
            label: t('updateNow'),
            onPress,
          }}
          msToHide={0}
        />
      </StyledPaymentNotice>
    )
  );
}
