import { useCallback, useEffect, useRef, useState } from 'react';
import { eventEmitter } from '@lawnstarter/customer-modules/helpers';

import { useScrollBlock, useWindowSize } from '@src/hooks';

import type { BottomSheetMethods } from '@devvie/bottom-sheet';
import type { MobileHeaderEvents } from '@src/enums';

/**
 * This controller lives in customer-web until we implement
 * a bottom sheet that can be used by customer-app as well.
 */
export function useBottomSheetController({ pressEvent }: { pressEvent: MobileHeaderEvents }) {
  const { height } = useWindowSize();
  const [bottomSheetHeight, setBottomSheetHeight] = useState('10%');
  const [blockScroll, allowScroll] = useScrollBlock();
  const [sheetVisibility, updateSheetVisibility] = useState(false);
  const sheetRef = useRef<BottomSheetMethods>(null);

  // Listen to open requests
  useEffect(() => {
    const helpDeskSelectPress = () => {
      sheetRef.current?.open();
      blockScroll();
      if (!sheetVisibility) {
        updateSheetVisibility(true);
      }
    };

    const source = eventEmitter.on(pressEvent, helpDeskSelectPress);

    return () => {
      source.removeListener(pressEvent, helpDeskSelectPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeBottomSheet = useCallback(() => {
    sheetRef.current?.close();
    allowScroll();
  }, [allowScroll]);

  return {
    height,
    sheetRef,
    allowScroll,
    sheetVisibility,
    closeBottomSheet,
    bottomSheetHeight,
    setBottomSheetHeight,
    updateSheetVisibility,
  };
}
