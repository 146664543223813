import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useUserNotificationPreferencesController } from '@lawnstarter/customer-modules/controllers';
import { t } from '@lawnstarter/customer-modules/services';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Text } from '@lawnstarter/ls-react-common/atoms';
import { UserNotificationPreference } from '@lawnstarter/ls-react-common/enums';
import { CategorySwitchesForm } from '@lawnstarter/ls-react-common/organisms';

import { Loader } from '@src/components';

import { DetailsContainer, PageContainer, styles, TitleContainer } from './styles';

import type { CategorySwitchesFormData } from '@lawnstarter/ls-react-common/organisms';

export const NotificationPreferencesScreen = () => {
  const { isLoading, isUpdating, notificationPreferences, updateNotificationPreferences } =
    useUserNotificationPreferencesController();

  const theme = useAppTheme();
  const style = useMemo(() => styles(theme), [theme]);

  const { control, watch, setValue } = useForm<
    CategorySwitchesFormData<UserNotificationPreference>
  >({
    defaultValues: {
      [UserNotificationPreference.ServiceReminderEmail]: false,
      [UserNotificationPreference.ServiceReminderSms]: false,
      [UserNotificationPreference.ServiceReminderPush]: false,
      [UserNotificationPreference.ServiceCompletedEmail]: false,
      [UserNotificationPreference.ServiceCompletedSms]: false,
      [UserNotificationPreference.ServiceCompletedPush]: false,
      [UserNotificationPreference.ProMessageEmail]: false,
      [UserNotificationPreference.ProMessageSms]: false,
      [UserNotificationPreference.ProMessagePush]: false,
    },
  });

  const categories = [
    {
      title: t('notificationPreferences.upcomingServiceReminders'),
      items: [
        {
          label: t('notificationPreferences.types.email'),
          name: UserNotificationPreference.ServiceReminderEmail,
        },
        {
          label: t('notificationPreferences.types.sms'),
          name: UserNotificationPreference.ServiceReminderSms,
        },
        {
          label: t('notificationPreferences.types.push'),
          name: UserNotificationPreference.ServiceReminderPush,
        },
      ],
    },
    {
      title: t('notificationPreferences.completedServiceNotifications'),
      items: [
        {
          label: t('notificationPreferences.types.email'),
          name: UserNotificationPreference.ServiceCompletedEmail,
        },
        {
          label: t('notificationPreferences.types.sms'),
          name: UserNotificationPreference.ServiceCompletedSms,
        },
        {
          label: t('notificationPreferences.types.push'),
          name: UserNotificationPreference.ServiceCompletedPush,
        },
      ],
    },
    {
      title: t('notificationPreferences.proMessages'),
      items: [
        {
          label: t('notificationPreferences.types.email'),
          name: UserNotificationPreference.ProMessageEmail,
        },
        {
          label: t('notificationPreferences.types.sms'),
          name: UserNotificationPreference.ProMessageSms,
        },
        {
          label: t('notificationPreferences.types.push'),
          name: UserNotificationPreference.ProMessagePush,
        },
      ],
    },
  ];

  useEffect(() => {
    if (notificationPreferences) {
      Object.entries(notificationPreferences).forEach(([key, value]) => {
        setValue(key as UserNotificationPreference, value as boolean);
      });
    }
  }, [notificationPreferences, setValue]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name) {
        const payload = {
          [name as UserNotificationPreference]: value[name as UserNotificationPreference],
        };
        updateNotificationPreferences(payload);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, updateNotificationPreferences]);

  const renderTitle = useCallback(
    () => (
      <div className="flex flex-col justify-center h-[80px] bg-primary mb-6 pl-6">
        <Text variant="titleLarge" style={style.title}>
          {t('notificationPreferences.title')}
        </Text>
      </div>
    ),
    [style],
  );

  return (
    <PageContainer>
      <TitleContainer>{renderTitle()}</TitleContainer>
      <DetailsContainer>
        {isLoading ? (
          <Loader testID="loader" />
        ) : (
          <CategorySwitchesForm categories={categories} disabled={isUpdating} control={control} />
        )}
      </DetailsContainer>
    </PageContainer>
  );
};
