import { useFlags } from 'flagsmith/react';
import { snakeCase } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { Divider } from 'react-native-paper';
import { generatePath, Navigate, useLocation, useParams } from 'react-router-dom';
import {
  useMyServicesController,
  useServicesController,
} from '@lawnstarter/customer-modules/controllers';
import { FeatureFlags, WebRoutes } from '@lawnstarter/customer-modules/enums';
import {
  getBrand,
  getHelpDeskActions,
  getJobInformation,
  getJobSummary,
  getPropertyDetails,
  getScheduleEvents,
  getServiceLinks,
  getSupportWebRoute,
  SupportActions,
} from '@lawnstarter/customer-modules/helpers';
import { useFeatureFlag } from '@lawnstarter/customer-modules/hooks';
import { t } from '@lawnstarter/customer-modules/services';
import {
  disputes_initCurrentDispute,
  properties_currentPropertySelector,
  useGetMarketFlagQuery,
} from '@lawnstarter/customer-modules/stores/modules';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Icon, Text } from '@lawnstarter/ls-react-common/atoms';
import {
  DisputeType,
  ScheduleEventStatus,
  ScheduleStatus,
  ScheduleType,
  ServiceQuoteStatus,
  ServiceStandard as ServiceQuoteType,
  ServiceSourceType as SourceType,
} from '@lawnstarter/ls-react-common/enums';
import { ScheduleEventDetails } from '@lawnstarter/ls-react-common/molecules';
import { ActionList } from '@lawnstarter/ls-react-common/organisms';

import {
  DetailsHeader,
  HelpDeskBottomSheet,
  Loader,
  ManualQuoteServiceWrapper,
  RecommendedServiceWrapper,
  RequiredServiceWrapper,
  ServiceDetailsLink,
} from '@src/components';
import { DEVICE_WIDTH_BREAKPOINTS } from '@src/constants';
import { useAppContext } from '@src/contexts';
import { splitSummaryByIconAvailability } from '@src/helpers';
import {
  useAppDownloadModal,
  useDispatch,
  useRouteNavigation,
  useSelector,
  useWindowSize,
} from '@src/hooks';
import { errorService } from '@src/services';

import { style, StyleDetailsWrapper, StyledHelpDeskContainer } from './styles';

import type { Action } from '@lawnstarter/customer-modules/helpers';
import type {
  JobInformation,
  ManualQuoteInformation,
  RecommendedServiceInformation,
  RequiredServiceInformation,
  ScheduleEvent,
} from '@lawnstarter/ls-react-common/types';

export function ServiceDetailsScreen() {
  const brand = getBrand();
  const dispatch = useDispatch();
  const location = useLocation();
  const { setTitle, setSubTitle } = useAppContext();
  const { navigate } = useRouteNavigation();
  const { showAppDownloadModal } = useAppDownloadModal();
  const { propertyId, scheduleId: routeScheduleId, sourceId: routeSourceId } = useParams();
  const { width: deviceWidth } = useWindowSize();

  const isCancellationFlowEnabled = useFeatureFlag(FeatureFlags.ALLOW_CANCELLATION_FLOW_WEB);

  const theme = useAppTheme();
  const styles = useMemo(() => style({ theme }), [theme]);

  const { enabled: isNewServicesLayout } = useFlags([FeatureFlags.SERVICES_TAB_IMPROVEMENTS_WEB])[
    FeatureFlags.SERVICES_TAB_IMPROVEMENTS_WEB
  ];

  const oldServicesControllerResult = useMyServicesController({ errorService });
  const newServicesControllerResult = useServicesController();

  const { isLoading } = useMemo(() => {
    return isNewServicesLayout ? newServicesControllerResult : oldServicesControllerResult;
  }, [isNewServicesLayout, newServicesControllerResult, oldServicesControllerResult]);

  const scheduleId = useMemo(() => {
    return (
      routeScheduleId ??
      (isNewServicesLayout
        ? (newServicesControllerResult.items ?? [])[0]?.scheduleId?.toString()
        : (oldServicesControllerResult.subscriptions ?? [])[0]?.params?.schedule_id?.toString())
    );
  }, [
    routeScheduleId,
    isNewServicesLayout,
    newServicesControllerResult,
    oldServicesControllerResult,
  ]);

  const sourceId = useMemo(() => {
    return (
      routeSourceId ??
      (isNewServicesLayout
        ? (newServicesControllerResult.items ?? [])[0]?.sourceId?.toString()
        : (oldServicesControllerResult.subscriptions ?? [])[0]?.params?.source_id?.toString())
    );
  }, [
    routeSourceId,
    isNewServicesLayout,
    newServicesControllerResult,
    oldServicesControllerResult,
  ]);

  const isRecommendedQuote = useMemo(() => {
    if (location.pathname.includes('recommendation')) return true;
    if (!isNewServicesLayout || scheduleId) return false;
    const firstItem = (newServicesControllerResult.items ?? [])[0];
    if (!firstItem) return false;
    return (
      firstItem.sourceType === SourceType.ServiceQuote &&
      (!firstItem.source?.type || firstItem.source?.type === ServiceQuoteType.Recommended)
    );
  }, [location.pathname, isNewServicesLayout, newServicesControllerResult.items, scheduleId]);

  const isRequiredQuote = useMemo(() => {
    if (location.pathname.includes('requiredservice')) return true;
    if (!isNewServicesLayout || scheduleId) return false;
    const firstItem = (newServicesControllerResult.items ?? [])[0];
    if (!firstItem) return false;
    return (
      firstItem.sourceType === SourceType.ServiceQuote &&
      firstItem.source?.type === ServiceQuoteType.Required
    );
  }, [location.pathname, isNewServicesLayout, newServicesControllerResult.items, scheduleId]);

  const isServiceQuote = useMemo(() => {
    return isRecommendedQuote || isRequiredQuote;
  }, [isRecommendedQuote, isRequiredQuote]);

  const isInstantQuote = useMemo(() => {
    if (location.pathname.includes('instant-quote')) return true;
    if (!isNewServicesLayout || scheduleId || isServiceQuote) return false;
    const firstItem = (newServicesControllerResult.items ?? [])[0];
    if (!firstItem) return false;
    return firstItem.sourceType === SourceType.InstantQuote;
  }, [
    isNewServicesLayout,
    isServiceQuote,
    location.pathname,
    newServicesControllerResult.items,
    scheduleId,
  ]);

  const schedule = useSelector((state) =>
    scheduleId ? state.schedules.schedulesById[scheduleId] : undefined,
  );

  const property = useSelector(properties_currentPropertySelector);

  const isLoadingSchedule = useSelector(
    ({ schedules, properties }) =>
      Object.keys(schedules.schedulesById).length === 0 &&
      Object.keys(properties.propertiesById).length === 0,
  );

  const propertyDetails = useMemo(() => getPropertyDetails({ property }), [property]);

  const { data: newHelpFlowFlag } = useGetMarketFlagQuery(
    {
      marketId: property?.market_id,
      flagName: 'zendesk.customer.messaging',
    },
    { skip: !property },
  );

  const supportPath = useMemo(() => {
    return newHelpFlowFlag?.is_enabled ? WebRoutes.support : getSupportWebRoute(brand.slug);
  }, [newHelpFlowFlag, brand]);

  const scheduleEvents = useMemo(() => (schedule ? getScheduleEvents(schedule) : []), [schedule]);

  const lastEvent: ScheduleEvent | undefined = useMemo(() => scheduleEvents[0], [scheduleEvents]);

  const lastCompletedEvent = useMemo(
    () =>
      lastEvent?.status === ScheduleEventStatus.Completed
        ? lastEvent
        : scheduleEvents.find(({ status }) => status === ScheduleEventStatus.Completed),
    [lastEvent, scheduleEvents],
  );

  const jobInformation = useMemo(() => {
    // Avoid throwing errors during property change while this screen is open.
    if (!property || (propertyId && propertyId !== String(property.id))) {
      return undefined;
    }

    const intent = {
      ...(isRecommendedQuote && { searchFor: ServiceQuoteType.Recommended }),
      ...(isRequiredQuote && { searchFor: ServiceQuoteType.Required }),
      resourceId: isServiceQuote ? Number(sourceId) : Number(lastEvent?.id),
    };

    const isDisputable = Boolean(
      lastCompletedEvent?.is_completion_disputable ?? lastEvent?.is_completion_disputable,
    );

    return {
      ...getJobInformation({ schedule, propertyDetails, errorService, intent }),
      isDisputable: isDisputable,
    } as JobInformation;
  }, [
    isRecommendedQuote,
    isRequiredQuote,
    isServiceQuote,
    lastCompletedEvent,
    lastEvent,
    property,
    propertyDetails,
    propertyId,
    schedule,
    sourceId,
  ]);

  useEffect(() => {
    const serviceName = jobInformation?.service?.formattedName;
    if (serviceName) {
      if (deviceWidth >= DEVICE_WIDTH_BREAKPOINTS.tablet.max) {
        setTitle(serviceName);
        setSubTitle(null);
      } else {
        setTitle(t('tabs.myServices'));
        setSubTitle(serviceName);
      }
    } else {
      setTitle(t('tabs.myServices'));
      setSubTitle(null);
    }
  }, [deviceWidth, jobInformation?.service?.formattedName, setSubTitle, setTitle]);

  const jobSummary = useMemo(
    () =>
      getJobSummary({ details: jobInformation, errorService }).reduce(
        splitSummaryByIconAvailability,
        {
          withIcons: [],
          withoutIcons: [],
        },
      ),
    [jobInformation],
  );

  const helpDeskActions = useMemo(
    () =>
      getHelpDeskActions({
        schedule,
        errorService,
        jobInformation,
        propertyId: property?.id,
      }),
    [schedule, jobInformation, property],
  );

  function getDisputeTypeByTitle(title: string) {
    return (
      {
        [t('supportActions.poorQuality')]: DisputeType.Quality,
        [t('supportActions.partialCompletion')]: DisputeType.PartialComplete,
        [t('supportActions.proNeverShowedUp')]: DisputeType.NoWorkDone,
      }[title] ?? DisputeType.Quality
    );
  }

  function onHelpDeskAction(action: Action) {
    const href = action.href as WebRoutes;

    if (href === WebRoutes.changeMyPro) {
      return navigate(href, { params: action.parameters });
    }

    if (href === WebRoutes.support) {
      return navigate(supportPath, { params: action.parameters });
    }

    if (
      [
        WebRoutes.disputeLocation,
        WebRoutes.disputeNotes,
        WebRoutes.disputePhotos,
        WebRoutes.disputeReason,
      ].includes(href)
    ) {
      dispatch(
        disputes_initCurrentDispute({
          property_id: property?.id,
          schedule_id: Number(scheduleId),
          scheduleevent_id: lastCompletedEvent?.id ?? lastEvent?.id ?? 0,
          dispute_type: getDisputeTypeByTitle(action.title),
        }),
      );

      return navigate(href, { params: action.parameters });
    }

    if (href === WebRoutes.reportDamage) {
      return navigate(href, { params: action.parameters });
    }

    showAppDownloadModal();
  }

  const supportActions = useMemo(() => {
    if (!jobInformation || jobInformation?.skipped?.isSkippedInPast) {
      return [];
    }

    return SupportActions.for(jobInformation.type)
      .usingErrorService(errorService)
      .having(jobInformation.service?.unformattedName)
      .in(jobInformation.lastEventStatus)
      .withParams({
        property_id: property.id,
        schedule_id: scheduleId,
        scheduleEventId: schedule?.next_scheduleevent_id,
        ...(schedule?.next_starts_at && { nextStartDate: schedule.next_starts_at }),
      })
      .getScheduleActions({
        frequency: jobInformation.frequency,
      }) as Action[];
  }, [jobInformation, property, schedule, scheduleId]);

  const serviceLinks = useMemo(
    () =>
      jobInformation &&
      getServiceLinks({
        schedule,
        jobInformation,
        propertyId: property?.id,
      }),
    [schedule, jobInformation, property],
  );

  /* Wrappers must only take care of specialized rendering */
  const renderWrapper = useCallback<() => React.ReactNode | boolean>(() => {
    const isCustomerPendingMQ =
      (jobInformation as ManualQuoteInformation).scheduleStatus === ScheduleStatus.PendingCustomer;

    const isReadyRequiredRecommendedService =
      (jobInformation as RequiredServiceInformation | RecommendedServiceInformation)
        ?.lastEventStatus === ServiceQuoteStatus.Ready;

    const theWrapper = {
      [ScheduleType.Service]: false,
      [ScheduleType.InstantQuote]: false,
      [ScheduleType.QualityAssurance]: false,
      [ScheduleType.PreAuthorizedWork]: false,
      [ServiceQuoteType.Required]:
        isReadyRequiredRecommendedService &&
        (() => (
          <RequiredServiceWrapper
            data-testid="required"
            service={jobInformation as RequiredServiceInformation}
          />
        )),

      [ServiceQuoteType.Recommended]:
        isReadyRequiredRecommendedService &&
        (() => (
          <RecommendedServiceWrapper
            data-testid="recommended"
            service={jobInformation as RecommendedServiceInformation}
          />
        )),

      [ScheduleType.ManualQuote]:
        isCustomerPendingMQ &&
        (() => (
          <ManualQuoteServiceWrapper
            data-testid="manualquote"
            targetScheduleId={Number(scheduleId)}
            service={jobInformation as ManualQuoteInformation}
          />
        )),
    }[jobInformation?.type as ServiceQuoteType | ScheduleType];

    return (
      typeof theWrapper === 'function' && (
        <div>
          {theWrapper()}

          <StyledHelpDeskContainer>
            <Divider style={{ marginVertical: theme.spacing.s3 }} />
          </StyledHelpDeskContainer>
        </div>
      )
    );
  }, [jobInformation, scheduleId, theme]);

  const renderServiceLinks = useCallback(() => {
    const renderedLinks = serviceLinks?.map(({ key, ...linkProps }) => (
      <ServiceDetailsLink key={key} {...linkProps} />
    ));

    if (serviceLinks && serviceLinks.length > 0) {
      renderedLinks?.push(
        <Divider key="link-divider" style={{ marginVertical: theme.spacing.s3 }} />,
      );
    }

    return renderedLinks;
  }, [serviceLinks, theme]);

  if (isLoading || isLoadingSchedule) {
    return <Loader testID="service-details-loader" />;
  }

  if (!property || !jobInformation) {
    return <Navigate replace to={WebRoutes.services} />;
  }

  if (isInstantQuote && property?.id && sourceId) {
    return (
      <Navigate
        replace
        to={generatePath(WebRoutes.pendingInstantQuote, {
          propertyId: property.id,
          sourceId: sourceId,
        })}
      />
    );
  }

  const renderSupportActions = () =>
    supportActions?.length && (
      <div
        data-testid="support-actions"
        style={{
          marginLeft: `-${theme.spacing.s2}px`,
        }}
      >
        <ActionList
          items={supportActions.map(({ title, subtitle, href, parameters }) => ({
            subtitle,
            label: title,
            action: () => {
              if (
                [
                  WebRoutes.skipService,
                  WebRoutes.changeSchedule,
                  WebRoutes.pauseService,
                  WebRoutes.oneTimeReschedule,
                  WebRoutes.resumePausedService,
                  isCancellationFlowEnabled && WebRoutes.cancelService,
                ].includes(href as WebRoutes)
              ) {
                return navigate(href as WebRoutes, { params: parameters });
              }

              showAppDownloadModal();
            },
            trackID: `${snakeCase(title)}-service_details_screen`,
          }))}
        />

        <Divider style={{ marginBottom: theme.spacing.s3 }} />
      </div>
    );

  const renderHelpdeskActions = () => {
    return (
      <StyledHelpDeskContainer data-testid="helpdesk-actions">
        <Text style={styles.helpDeskTitle}>{t('helpDesk')}</Text>

        <ActionList
          items={helpDeskActions.map((action) => ({
            label: action.title,
            trackID: `${snakeCase(action.title)}-service_details_screen`,
            icon: (
              <Icon
                name={action.icon === 'support-action' ? 'comment-question' : 'phone-message'}
              />
            ),
            action: () => onHelpDeskAction(action),
          }))}
        />
      </StyledHelpDeskContainer>
    );
  };

  const isContinuousInformation = (index: number) =>
    index === jobSummary.withoutIcons.length - 1 && (serviceLinks ?? []).length > 0;

  return (
    <div className="bg-whitePrimary -mt-4 pt-4">
      <DetailsHeader />

      <StyleDetailsWrapper>
        <ScheduleEventDetails.Summary items={jobSummary.withIcons} badge={jobInformation.badge} />
        {jobSummary.withoutIcons.map(({ title, description }, index) => (
          <ScheduleEventDetails.Information
            key={description}
            title={title}
            description={description}
            continuous={isContinuousInformation(index)}
          />
        ))}

        {renderServiceLinks()}
        {renderWrapper() || renderSupportActions() || false}

        {renderHelpdeskActions()}

        <HelpDeskBottomSheet actions={helpDeskActions} onActionPress={onHelpDeskAction} />
      </StyleDetailsWrapper>
    </div>
  );
}
