import { useCallback, useEffect } from 'react';
import { Pressable, View } from 'react-native';
import { t } from '@lawnstarter/customer-modules/services';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Icon } from '@lawnstarter/ls-react-common/atoms';
import { ProgressHeader } from '@lawnstarter/ls-react-common/molecules';

import { BackButton } from '@src/components/atoms';
import { useAppContext } from '@src/contexts';

import {
  BackButtonWrapper,
  Container,
  Description,
  HelpButton,
  LayoutWrapper,
  SelectOptionWrapper,
  Title,
  TitleWrapper,
} from './styles';

import type { ReactNode } from 'react';

export interface PoolCleaningQuestionProps {
  title: string;
  description: string;
  progress?: number;
  onBackButtonPress?: () => void;
  onHelpButtonPress?: () => void;
  children?: ReactNode;
}

export function OrderPoolCleaningLayout({
  title,
  description,
  progress,
  onBackButtonPress,
  onHelpButtonPress,
  children,
}: PoolCleaningQuestionProps) {
  const theme = useAppTheme();
  const { setBackButtonAction } = useAppContext();

  useEffect(() => {
    setBackButtonAction(onBackButtonPress ? () => onBackButtonPress : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onBackButtonPress]);

  const renderProgressBar = useCallback(
    () => (
      <ProgressHeader
        progress={progress || 0}
        progressText={t('services.poolCleaning.orderService.title')}
      />
    ),
    [progress],
  );

  const renderHelpButton = useCallback(
    () => (
      <HelpButton>
        <Pressable onPress={onHelpButtonPress}>
          <Icon name="help-circle-outline" color={theme.colors.primary} size={theme.spacing.s8} />
        </Pressable>
      </HelpButton>
    ),
    [onHelpButtonPress, theme],
  );

  return (
    <Container>
      <BackButtonWrapper>
        <BackButton {...(onBackButtonPress && { onPress: onBackButtonPress })} />
      </BackButtonWrapper>
      {progress && renderProgressBar()}
      <LayoutWrapper>
        <View style={{ flex: 1 }}>
          <TitleWrapper>
            <Title>{title}</Title>
            {onHelpButtonPress && renderHelpButton()}
          </TitleWrapper>
          <Description style={{ color: theme.colors.gray.tertiary }}>{description}</Description>
        </View>
        <SelectOptionWrapper>{children}</SelectOptionWrapper>
      </LayoutWrapper>
    </Container>
  );
}
