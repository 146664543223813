import { useMemo } from 'react';
import { getFaqByTopic } from '@lawnstarter/customer-modules/helpers';
import { t } from '@lawnstarter/customer-modules/services';
import { QuestionList } from '@lawnstarter/ls-react-common/organisms';

import { MobileHeaderEvents } from '@src/enums';

import { BaseBottomSheet } from '../BaseBottomSheet';

import type { FaqTopics } from '@lawnstarter/customer-modules/enums';

interface FaqsBottomSheetProps {
  origin: string;
  topic: FaqTopics;
}
export const FaqsBottomSheet = ({ origin, topic }: FaqsBottomSheetProps) => {
  const faqItems = useMemo(() => getFaqByTopic({ topic }), [topic]);

  return (
    <BaseBottomSheet openEvent={MobileHeaderEvents.onFaqsPressEvent} title={t('customerFaq.faq')}>
      {faqItems.map(({ title, questions }, idx) => (
        <QuestionList
          origin={origin}
          questions={questions}
          openFirstQuestion={false}
          key={title?.replace(/ /g, '') ?? idx}
        />
      ))}
    </BaseBottomSheet>
  );
};
