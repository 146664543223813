import type { Location } from 'react-router-dom';

const footerExclusionRoutes = [
  '/order/fertilization',
  '/request/standalone_pool_cleaning',
  '/order/standalone_pool_cleaning',
];

export function shouldHideFooterInCurrentRoute(location: Location<unknown>) {
  return footerExclusionRoutes.some((route) => location.pathname.includes(route));
}
