import { useFlags } from 'flagsmith/react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { FeatureFlags, WebRoutes } from '@lawnstarter/customer-modules/enums';
import {
  formatHaulawayDescription,
  getHaulawayFieldData,
  MQHowQuotingWorksModal,
  MQSubmitSuccessModal,
} from '@lawnstarter/customer-modules/helpers';
import {
  getTrackingEventSource,
  useOrderServiceViewedTracking,
} from '@lawnstarter/customer-modules/hooks';
import { t } from '@lawnstarter/customer-modules/services';
import {
  orderServices_submitQuote,
  properties_currentPropertyIdSelector,
} from '@lawnstarter/customer-modules/stores/modules';
import { Button } from '@lawnstarter/ls-react-common/atoms';
import { ServiceType } from '@lawnstarter/ls-react-common/enums';
import { useApiTracking, useModal } from '@lawnstarter/ls-react-common/hooks';
import { HaulawayForm, ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import { OrderServiceHeader } from '@src/components';
import { useDispatch, useRouteNavigation, useSelector } from '@src/hooks';
import { errorService } from '@src/services';

import { StyledHaulawayFormContainer } from './styles';

import type {
  HaulawayFormData,
  HaulawayFormFieldData,
} from '@lawnstarter/ls-react-common/organisms';

export function OrderHaulawayScreen() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { navigate } = useRouteNavigation();
  const propertyId = useSelector(properties_currentPropertyIdSelector);
  const isUpdating = useSelector(({ orderServices }) => orderServices.loadingStatus.isUpdating);

  const stepModal = useModal(ModalTemplate.STEPS);
  const QuoteSubmittedModal = useModal(ModalTemplate.ACTION_CONFIRMATION);

  const { control, handleSubmit, watch } = useForm<HaulawayFormData>();
  const fieldData: HaulawayFormFieldData = {
    ...getHaulawayFieldData(),
    images: {
      ...getHaulawayFieldData().images,
      isUploading: false,
    },
  };

  const apiTracking = useApiTracking()!;
  const carouselAbTest = useFlags([FeatureFlags.RECOMMENDATION_CAROUSEL_AB_TEST])[
    FeatureFlags.RECOMMENDATION_CAROUSEL_AB_TEST
  ];

  const source = carouselAbTest.enabled
    ? getTrackingEventSource(location.state?.source, carouselAbTest.value)
    : undefined;
  useOrderServiceViewedTracking(apiTracking, ServiceType.Haulaway, errorService, source);

  const submit = async (data: HaulawayFormData) => {
    const { success } = await dispatch(
      orderServices_submitQuote({
        updatedFields: {
          property_id: propertyId,
          service_id: 4810,
          service_str_id: ServiceType.Haulaway,
          budget: data.budget,
          pendingImages: data.images,
          desiredWorkDetails: formatHaulawayDescription(data),
        },
        source,
      }),
    );

    if (success) {
      return QuoteSubmittedModal.show(MQSubmitSuccessModal(navigate, WebRoutes.services));
    }
  };

  return (
    <StyledHaulawayFormContainer>
      <OrderServiceHeader
        section={t('forms.quote.request')}
        serviceName={t('forms.haulaway.name')}
      />

      <HaulawayForm
        control={control}
        fieldData={fieldData}
        watch={watch}
        isUpdating={isUpdating}
        infoModal={() => stepModal.show(MQHowQuotingWorksModal())}
      />

      <Button
        trackID="submit"
        testID="submit"
        mode="contained"
        onPress={handleSubmit(submit)}
        loading={isUpdating}
        disabled={isUpdating}
      >
        {t('recommendations.getQuote')}
      </Button>
    </StyledHaulawayFormContainer>
  );
}
