import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { setCurrentProperty } from '@lawnstarter/customer-modules/helpers';
import { useGraphqlClientAuth } from '@lawnstarter/customer-modules/hooks';
import {
  featureFlags_getFlags,
  properties_currentPropertyIdSelector,
  properties_currentPropertySelector,
  properties_updateCurrentProperty,
} from '@lawnstarter/customer-modules/stores/modules';
import { ModalViewer } from '@lawnstarter/ls-react-common/organisms';

import { Loader, PropertyBottomSheet, StyledContainedSection } from '@src/components';
import { HEADER_ROUTE_OPTIONS } from '@src/constants';
import { AppContextProvider } from '@src/contexts';
import { isLeadOrOnboardingIncomplete, setLoginIntent } from '@src/helpers';
import { useDispatch, useRouteNavigation, useSelector } from '@src/hooks';
import { FooterNavbar, Header, useShouldHideMobileFooter } from '@src/layout';

import type { User } from '@lawnstarter/ls-react-common/types';
import type { CurrentTabProps } from '@src/types';

interface Props extends CurrentTabProps {
  user?: User;
  children?: React.ReactNode;
}

export function AuthorizedTemplate({ user, currentTab, children }: Props) {
  const { navigate } = useRouteNavigation();

  const dispatch = useDispatch();

  const currentParams = useParams();
  const { pathname } = useLocation();
  const currentProperty = useSelector(properties_currentPropertySelector);
  const currentPropertyId = useSelector(properties_currentPropertyIdSelector);
  const authToken = localStorage.getItem('@LawnStarter:authToken');
  const featureFlags = useSelector((state) => state.featureFlags.flags);
  const shouldHideFooter = useShouldHideMobileFooter();

  const currentTabLink = useMemo(() => {
    const itemsFound = HEADER_ROUTE_OPTIONS.filter(({ tab }) => currentTab === tab);
    return (itemsFound[0] ?? HEADER_ROUTE_OPTIONS[0]).link;
  }, [currentTab]);

  const paramsPropertyId = useMemo(() => {
    return currentParams?.propertyId;
  }, [currentParams]);

  // Authenticate GraphQL clients
  const { authenticated } = useGraphqlClientAuth();

  // Ensure we'll always scroll to the top when moving between pages
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    // If the accessed route has a property ID, set it as the current property
    // This lets the URL parameter have priority over the property in storage
    if (paramsPropertyId && !currentPropertyId) {
      dispatch(properties_updateCurrentProperty({ property_id: paramsPropertyId }));
      setCurrentProperty({ propertyId: paramsPropertyId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // When the current property is set, check if there's a property ID in the route
    // If there is, and it's different from the current property, redirect to a parent screen
    if (
      currentProperty &&
      paramsPropertyId &&
      Number(paramsPropertyId) !== Number(currentProperty.id)
    ) {
      return navigate(currentTabLink);
    }
  }, [currentProperty, currentTabLink, navigate, paramsPropertyId]);

  useEffect(() => {
    if (Object.keys(featureFlags).length === 0) {
      dispatch(featureFlags_getFlags());
    }
  }, [dispatch, featureFlags]);

  useEffect(() => {
    // redirecting users to legacy web if they have not finished onboarding or are considered a lead.
    isLeadOrOnboardingIncomplete({ user, authToken });
  }, [authToken, user]);

  if (_.isEmpty(user)) {
    const { pathname, search } = window.location;
    setLoginIntent({ pathname: `${pathname}${search}` });

    return <Navigate to={WebRoutes.login} replace />;
  }

  return (
    <div className="flex flex-col min-h-screen">
      <AppContextProvider>
        <Header currentTab={currentTab} />

        <StyledContainedSection $hideFooter={shouldHideFooter}>
          {authenticated ? children ?? <Outlet /> : !authenticated ? <Loader /> : null}
        </StyledContainedSection>
      </AppContextProvider>
      <FooterNavbar currentTab={currentTab} />

      {/* Moving the PropertyBottomSheet and ModalViewer here is the only way to use useNavigate inside it because of the RouterContext */}
      <PropertyBottomSheet />
      <ModalViewer />
    </div>
  );
}
