import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingList,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
} from '@floating-ui/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  properties_currentPropertySelector,
  properties_propertiesInArraySelector,
} from '@lawnstarter/customer-modules/stores/modules';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Icon, Spinner, Text } from '@lawnstarter/ls-react-common/atoms';

import { AddPropertyButton, PropertyOptionButton } from '@src/components';
import { SelectContext } from '@src/contexts';
import { useSelector, useWindowSize } from '@src/hooks';

import {
  StyledFloatingList,
  StyledHomeContainer,
  StyledPropertyTextWrapper,
  styles,
} from './styles';

import type { ReactNode } from 'react';

function Select({ children, selectedAddress }: { children: ReactNode; selectedAddress: string }) {
  const [isOpen, setIsOpen] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const theme = useAppTheme();
  const { height: deviceHeight } = useWindowSize();
  const floatingListRef = useRef<HTMLDivElement | null>(null);
  const style = useMemo(() => styles({ theme, deviceHeight }), [theme, deviceHeight]);

  const { refs, floatingStyles, context } = useFloating({
    placement: 'bottom-end',
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [flip()],
  });

  useEffect(() => {
    // Scroll top needs to be handled manually since the
    // list is always scrolled to the bottom when opened
    if (floatingListRef.current) {
      floatingListRef.current.addEventListener(
        'scroll',
        () => floatingListRef.current && setScrollTop(floatingListRef.current.scrollTop),
        { passive: true },
      );

      setTimeout(() => {
        floatingListRef.current?.scrollTo(0, scrollTop);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [floatingListRef.current]);

  const elementsRef = useRef<Array<HTMLElement | null>>([]);
  const labelsRef = useRef<Array<string | null>>([]);

  const handleSelect = useCallback(() => {
    setIsOpen(false);
  }, []);

  const listNav = useListNavigation(context, {
    listRef: elementsRef,
    activeIndex,
    onNavigate: setActiveIndex,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'listbox' });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    listNav,
    click,
    dismiss,
    role,
  ]);

  const selectContext = useMemo(
    () => ({
      activeIndex,
      getItemProps,
      handleSelect,
    }),
    [activeIndex, getItemProps, handleSelect],
  );

  return (
    <>
      <StyledHomeContainer
        style={style.homeContainer}
        ref={refs.setReference}
        tabIndex={0}
        {...getReferenceProps()}
      >
        <Icon name="home" size={theme.sizing.s6} color={theme.colors.gray.tertiary} />
        <StyledPropertyTextWrapper>
          <Text style={style.dropDownSpan}>
            {selectedAddress ? (
              selectedAddress
            ) : (
              <Spinner
                color={theme.colors.green.primary}
                size={theme.sizing.s5}
                style={{ marginHorizontal: theme.spacing.s5 }}
              />
            )}
          </Text>
        </StyledPropertyTextWrapper>
        <Icon name="chevron-down" size={theme.sizing.s6} color={theme.colors.gray.tertiary} />
      </StyledHomeContainer>
      <SelectContext.Provider value={selectContext}>
        {isOpen && (
          <FloatingFocusManager context={context} modal={false}>
            <StyledFloatingList
              ref={(el) => {
                refs.setFloating(el);
                floatingListRef.current = el;
              }}
              style={{ ...floatingStyles, ...style.listBox }}
              {...getFloatingProps()}
            >
              <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
                {children}
              </FloatingList>
            </StyledFloatingList>
          </FloatingFocusManager>
        )}
      </SelectContext.Provider>
    </>
  );
}

export function DesktopPropertySwitcher() {
  const theme = useAppTheme();
  const { height: deviceHeight } = useWindowSize();
  const style = useMemo(() => styles({ theme, deviceHeight }), [theme, deviceHeight]);
  const properties = useSelector(properties_propertiesInArraySelector);
  const selectedProperty = useSelector(properties_currentPropertySelector);
  const filteredProperties = properties.filter((property) => property.id !== selectedProperty?.id);

  return (
    <>
      <Select selectedAddress={selectedProperty?.street}>
        {filteredProperties.map((item) => {
          return (
            <PropertyOptionButton
              style={style.propertyOption}
              street={item.street}
              city={item.city}
              state={item.state}
              zip={item.zip}
              key={item.id}
              label={item.street}
              property_id={item.id}
            />
          );
        })}
        <span style={style.horizontalLine}></span>
        <AddPropertyButton />
      </Select>
    </>
  );
}
