import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';

import { useRouteCreator } from '@src/hooks';
import {
  AuthorizedProfileTemplate,
  AuthorizedTemplate,
  PastServicesScreen,
  PropertyDetailsQuestionsScreen,
  PropertyDetailsScreen,
} from '@src/pages';

import type { User } from '@lawnstarter/ls-react-common/types';
import type { RouteObject } from 'react-router-dom';

export function PropertyRouter({ user }: { user: User }): RouteObject[] {
  const { createRoute } = useRouteCreator();

  return [
    {
      path: WebRoutes.propertyDetails,
      element: <AuthorizedTemplate user={user} />,
      children: [
        createRoute({
          index: true,
          component: <PropertyDetailsScreen />,
        }),
      ],
    },
    {
      path: WebRoutes.pastServices,
      element: <AuthorizedProfileTemplate user={user} />,
      children: [
        createRoute({
          index: true,
          component: <PastServicesScreen />,
        }),
      ],
    },
    {
      path: WebRoutes.propertyDetailsQuestions,
      element: <AuthorizedTemplate user={user} />,
      children: [
        createRoute({
          index: true,
          title: t('services.poolCleaning.appTitles.answerQuestions'),
          component: <PropertyDetailsQuestionsScreen />,
        }),
      ],
    },
  ];
}
