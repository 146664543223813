import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { useFlags } from 'flagsmith/react';
import { useEffect, useMemo } from 'react';
import Slider from 'react-slick';
import {
  TRACKING_EVENT_EXPERIMENT_NAMES,
  TRACKING_EVENT_EXPERIMENT_PLATFORMS,
  TRACKING_EVENT_EXPERIMENT_VIEWED,
} from '@lawnstarter/customer-modules/constants';
import { FeatureFlags, NavigationSources } from '@lawnstarter/customer-modules/enums';
import { auth_signedUserSelector } from '@lawnstarter/customer-modules/stores/modules';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { AdBanner } from '@lawnstarter/ls-react-common/molecules';

import { useRouteNavigation, useSelector, useWindowSize } from '@src/hooks';
import { trackingService } from '@src/services';

import { StyledCardContainer, StyledCarouselContainer, StyledInnerCardContainer } from './styles';

import type { WebRoutes } from '@lawnstarter/customer-modules/enums';
import type { TrackParams } from '@lawnstarter/ls-react-common/services';
import type { CarouselProps } from './types';

export function RecommendationCarousel({
  items,
  itemBeforePress,
  dots,
  infinate,
  slidesToScroll,
  slidesToShow,
}: CarouselProps) {
  const theme = useAppTheme();
  const window = useWindowSize();
  const { navigate } = useRouteNavigation();
  const user = useSelector(auth_signedUserSelector);
  const carouselAbTest = useFlags([FeatureFlags.RECOMMENDATION_CAROUSEL_AB_TEST])?.[
    FeatureFlags.RECOMMENDATION_CAROUSEL_AB_TEST
  ];

  useEffect(() => {
    if (carouselAbTest?.enabled && user) {
      trackingService.track({
        event: TRACKING_EVENT_EXPERIMENT_VIEWED,
        userId: user.hash_id as any,
        data: {
          experimentName: TRACKING_EVENT_EXPERIMENT_NAMES.CAROUSEL,
          experimentVariation: carouselAbTest?.value,
          platform: TRACKING_EVENT_EXPERIMENT_PLATFORMS.WEB,
          // TODO: update this property type in ls-react-common to be more generic so there's no need to force a type cast
        } as TrackParams['data'],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const numberToShow = (width: number): number => {
    return width < 725 ? 1 : width <= 1023 ? 2 : 3;
  };

  const action = ({ routeName, params }: { routeName: WebRoutes; params: any }) => {
    navigate(routeName, {
      params,
      options: { state: { source: NavigationSources.RecommendationCarousel } },
    });
  };

  const renderItems = useMemo(() => {
    return items.map((item) => (
      <StyledCardContainer key={item.title}>
        <StyledInnerCardContainer
          style={{
            background: theme.colors.white.primary,
            boxShadow: '0px 1px 2px 2px lightGray',
          }}
        >
          <AdBanner
            title={
              carouselAbTest?.enabled && carouselAbTest?.value === 'v2'
                ? item.titleV2 || item.title
                : item.title
            }
            image={item.imageUrl}
            ctaText={item.actionText}
            beforePress={itemBeforePress}
            onPress={() => action(item.action)}
            description={item.text}
          />
        </StyledInnerCardContainer>
      </StyledCardContainer>
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemBeforePress, items, theme.colors.white.primary]);

  function SimpleSlider() {
    var settings = {
      dots: dots ?? true,
      infinite: infinate ?? true,
      speed: 500,
      slidesToShow: slidesToShow ?? numberToShow(window.width),
      slidesToScroll: slidesToScroll ?? numberToShow(window.width),
      arrows: false,
    };

    // carousel does not like only having one item in the array breaks UI
    if (items.length === 1) {
      return <div className="w-full flex justify-center">{renderItems}</div>;
    }

    return (
      <StyledCarouselContainer>
        <Slider {...settings}>{renderItems}</Slider>
      </StyledCarouselContainer>
    );
  }

  return <SimpleSlider />;
}
